export const LINEUP_SLOT_IDS = {
  PG: 0,
  SG: 1,
  SF: 2,
  PF: 3,
  C: 4,
  G: 5,
  F: 6,
  SG_SF: 7,
  G_F: 8,
  PF_C: 9,
  F_C: 10,
  UTIL: 11,
  BENCH: 12,
  IR: 13
};